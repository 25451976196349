<template>
  <b-overlay
    :show="isLoading"
    rounded="sm"
  >
    <validation-observer
      ref="saveFormRef"
      #default="{invalid}"
    >
      <b-form>
        <b-row>
          <!-- Name Building -->
          <b-col cols="12">
            <b-form-group label-for="employee">
              <template v-slot:label>
                Cán bộ <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Cán bộ"
                rules="required"
              >
                <v-select
                  v-model="dataMain.employeeId"
                  :options="employees"
                  :reduce="option => option.id"
                  label="fullName"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
            <b-form-group
              label="Vai trò"
              label-for="factResearchRoleId"
            >
              <template v-slot:label>
                Vai trò <span class="text-danger">*</span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Vai trò"
                rules="required"
              >
                <v-select
                  v-model="dataMain.factResearchRoleId"
                  :options="roles"
                  :reduce="option => option.id"
                  label="name"
                />
                <b-form-invalid-feedback :state="getElementState(errors)">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <div class="float-left">
              <b-button
                v-if="creatable"
                type="submit"
                variant="primary"
                class="mr-1"
                :disabled="invalid"
                @click="handleSaveData"
              >
                Lưu
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-overlay>
</template>

<script>
import {
  BForm,
  BFormInvalidFeedback,
  BButton,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import vSelect from 'vue-select'
import { getUser } from '@/auth/utils'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { hasPermissionForResource } from '@/utils'
import { PermissionCode, ResourceCode } from '@/const/code'

export default {
  name: 'FormAddCommitteeEmployee',
  components: {
    ValidationObserver,
    ValidationProvider,
    BForm,
    BFormInvalidFeedback,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    vSelect,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({ researchId: null }),
    },
    modalShow: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      user: getUser(),
      isLoading: false,
      statusOptions: [
        { value: 1, label: 'Đang hoạt động' },
        { value: 0, label: 'Không hoạt động' },
      ],
      dataMain: {
        researchId: null,
        employeeId: null,
        factResearchRoleId: null,
      },
    }
  },
  computed: {
    // calculate data from init data
    ...mapGetters({
      roles: 'factResearchRoles/dataArr',
      employees: 'employee/employees',
    }),
    creatable() {
      return hasPermissionForResource(PermissionCode.CREATE, ResourceCode.RESEARCH_REVIEW_COMMITTEE)
    },
  },
  async created() {
    this.dataMain.researchId = this.dataForm.researchId
    await Promise.all([
      this.getEmployees({ page: 1, itemsPerPage: 100 }),
      this.getRoles({ currentPage: 1, itemsPerPage: 100 }),
    ])
  },
  methods: {
    ...mapActions({
      saveData: 'research/saveResearchReviewCommittee',
      getEmployees: 'employee/getEmployee',
      getRoles: 'factResearchRoles/getData',
    }),

    async handleSaveData(e) {
      e.preventDefault()
      const valid = this.$refs
        .saveFormRef
        .validate()
      if (valid) {
        this.isLoading = true
        try {
          const response = await this.saveData(this.dataMain)
          if (response) {
            const { isSuccessful, message } = response
            if (isSuccessful) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'CheckIcon',
                  variant: 'success',
                },
              })
              this.$emit('successFormAdd')
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'XCircleIcon',
                  variant: 'danger',
                },
              })
            }
          }
        } catch (e) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: `[${e.code}] ${e.message}`,
              icon: 'XCircleIcon',
              variant: 'danger',
            },
          })
        } finally {
          this.isLoading = false
        }
      }
    },

    showToast(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        position: 'top-right',
        props: {
          title,
          icon,
          variant,
        },
      })
    },
    getElementState(errors) {
      return errors.length > 0 ? false : null
    },
  },
}
</script>
